(function(w, d) {
    function domReadyFunctions() {

        /* User Controls */
        function updateLoggedInState(){
            if (__tnt.user.loggedIn) {
                Array.from(d.getElementsByClassName('tn-user-screenname')).forEach(function(el) {
                    el.textContent = __tnt.user.screenName;
                });
                if (__tnt.user.avatar) {
                    Array.from(d.getElementsByClassName('tn-user-avatar')).forEach(function(el) {
                        el.setAttribute('src', __tnt.user.avatar);
                    });
                }
                Array.from(d.getElementsByClassName('tn-user-profile-url')).forEach(function(el) {
                    el.setAttribute('href', __tnt.scrubUrl(el.dataset.app + 'profile/' + __tnt.user.screenName + '/') );
                });
                Array.from(d.getElementsByClassName('logged-in')).forEach(function(el) {
                    if(el.nodeName == "SPAN"){
                        el.style.display = 'inline';
                    } else if(el.classList.contains('display-inline-block')){
                        el.style.display = 'inline-block';
                    } else {
                        el.style.display = 'block';
                    }                });
                Array.from(d.getElementsByClassName('not-logged-in')).forEach(function(el) {
                    el.style.display = 'none';
                });
            } else {
                Array.from(d.getElementsByClassName('not-logged-in')).forEach(function(el) {
                    if(el.nodeName == "SPAN"){
                        el.style.display = 'inline';
                    } else if(el.classList.contains('display-inline-block')){
                        el.style.display = 'inline-block';
                    } else {
                        el.style.display = 'block';
                    }
                });
                Array.from(d.getElementsByClassName('logged-in')).forEach(function(el) {
                    el.style.display = 'none';
                });
            }
        }
        
        window.addEventListener("tnt-user-status", function() {
            updateLoggedInState();
        });
        updateLoggedInState();

        /* Off Canvas Menus */
        Array.from(d.querySelectorAll('[data-toggle="offcanvas"]')).forEach(function(drawer) {
            drawer.addEventListener(__tnt.client.clickEvent, function(e) {
                e.preventDefault();
                e.stopPropagation();
                var drawerCls = drawer.dataset.target === 'left' ? 'active-left' : 'active-right';
                d.documentElement.classList.add('drawer-open', drawerCls);
            });
        });
        Array.from(d.getElementsByClassName('offcanvas-close-btn')).forEach(function(btn) {
            btn.addEventListener(__tnt.client.clickEvent, function(e) {
                e.preventDefault();
                e.stopPropagation();
                d.documentElement.classList.remove('drawer-open', 'active-left', 'active-right');
            });
        });

        /* Show or Hide Elements */
        Array.from(d.getElementsByClassName('hide-onload')).forEach(function(el) {
            el.style.display = 'none';
        });
        Array.from(d.getElementsByClassName('show-onload')).forEach(function(el) {
            el.style.display = 'block';
        });

        /* New Window */
        Array.from(d.querySelectorAll('[data-toggle="new-window"]')).forEach(function(el) {
            el.addEventListener('click', function(e) {
                e.preventDefault();
                var url = el.getAttribute('href'),
                    paramArray = [], 
                    paramObj = {
                        menubar: 'no',
                        toolbar: 'no',
                        resizeable: 'yes',
                        scrollbars: 'yes',
                        width: el.dataset.newWindowWidth,
                        height: el.dataset.newWindowHeight,
                        left: (screen.width / 2) - (el.dataset.newWindowWidth / 2),
                        top: (screen.height / 2) - (el.dataset.newWindowHeight / 2)
                    },
                    params = '';

                Object.keys(paramObj).forEach(function(key) {
                    paramArray.push([key, paramObj[key]].join('='));
                });
                params = paramArray.join(',');

                /* Append Hash */
                if (w.location.hash && el.dataset.sendHash === 'true') {
                    url += ('%23' + w.location.hash.substring(1));
                }
                w.open(url, '', params);
            });
        });
    }
    
    if (d.readyState == 'loading') {
        d.addEventListener('DOMContentLoaded', function() {
            domReadyFunctions();
        });
    } else {
        domReadyFunctions();
    }
})(window, document);

/* Save asset */
function tnSaveAsset(event, el) {
    event.preventDefault();
    
    var form = document.querySelector('.save-list-' + el.dataset.assetUuid + '-form');
    if (__tnt.user.loggedIn) {
        var uuid = form.dataset.uuid,
            btnArray,
            btnCls = 'save-list-' + uuid + '-btn',
            linkText,
            serialized = [],
            serializedData;

        for (var i = 0; i < form.elements.length; i++) {
            var field = form.elements[i];
            if (field.type === 'submit') continue;
            serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
        }
        serializedData = serialized.join('&');

        var xhr = new XMLHttpRequest();
        xhr.open('POST', form.getAttribute('action'), true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.setRequestHeader('x-requested-with', 'XMLHttpRequest');
        xhr.onreadystatechange = function() {
            if (xhr.readyState == 4) {
                if (xhr.status == 200) {
                    var result = JSON.parse(xhr.responseText);
                    btnArray = Array.from(document.getElementsByClassName(btnCls));
                    if (result.success) {
                        btnArray.forEach(function(el) {
                            el.classList.add('disabled');
                            el.setAttribute('title', 'Saved');
                            el.querySelector('.asset-save-icon').setAttribute('data-prefix', 'fas');
                            linkText = el.querySelector('.share-expand');
                            if (typeof(linkText) != 'undefined' && linkText != null) {
                                linkText.textContent = 'Saved';
                            }
                        });
                    } else {
                        btnArray.forEach(function(el) {
                            el.classList.add('btn-danger');
                            linkText = el.querySelector('.share-expand');
                            if (typeof(linkText) != 'undefined' && linkText != null) {
                                linkText.textContent = 'Error';
                            }
                        });
                        setTimeout(function() {
                            btnArray.forEach(function(el) {
                                el.classList.remove('btn-danger');
                                linkText = el.querySelector('.share-expand');
                                if (typeof(linkText) != 'undefined' && linkText != null) {
                                    linkText.textContent = 'Save';
                                }
                            });
                        }, 1000);
                    }
                } else {
                    __tnt.log('Error. Status Code: ' + xhr.status);
                }
            }
        };
        xhr.send(serializedData);
    } else {
        var currURL = window.location.href,
            getParams = window.location.search ? '&' : '?',
            refererURL = encodeURIComponent(currURL + getParams + 'save_asset=' + form.dataset.uuid);
        top.location.href = '/users/login/?referer_url=' + refererURL;
    }
}

/* DOM ready functions that require jQuery */
jQuery(document).ready(function($){
    $.ajaxSetup({cache: true});

    /* Tooltips */
    if ($('[data-toggle="tooltip"]').length > 0) {
        $('[data-toggle="tooltip"]').tooltip();
    }

    /* Sortable Tables */
    if(jQuery().stupidtable) {
        $(".table-sortable").stupidtable();
    }
});
